/* body {
  background-color: rgb(22, 22, 22);
} */

.home-page__container {
  border: solid 1px rgb(22, 22, 22);
  border-radius: 4px;
  margin: 10px auto;
  padding: 6px;
}

.center-btn {
  margin: 0 auto;
  display: block;
}
