/* Override the <Section />'s  title */
.card-section div:first-child  {
  display: block;
  width: 100%;
}

.card-section{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 40px 40px;
}

.card {
  position: relative;
  margin: 10px;
  padding-top: 0px;
}

.card h3{
  color: rgb(255, 107, 8);
}

.btn-launch{
  margin-top: 10px;
}
