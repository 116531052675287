/* Override form section header margin */
.container div[class*="header___"] {
  margin-bottom: 10px !important;
}

/* Link colors */
.container a {
  color: rgb(255, 107, 8);
  text-decoration: none; /* no underline */
}
.container a:link {
  color: rgb(255, 107, 8);
}
.container a:visited {
  color: rgb(255, 107, 8);
}
.container a:hover {
  color: white;
  background: rgb(255, 107, 8);
}
.container a:active {
  color: white;
  background: rgb(255, 107, 8);
}

/* Override button colors */
.container button[class*="button___"] {
  background-color: rgb(255, 107, 8);
}
.container button[class*="button___"]:hover {
  background-color: rgba(255, 107, 8, 0.8);
}

h1::selection,
h2::selection,
h3::selection,
p::selection,
div::selection,
li::selection {
  background: rgba(255, 107, 8, 0.8);
  color: white;
}

h1::-moz-selection,
h2::-moz-selection,
h3::-moz-selection,
p::-moz-selection,
div::-moz-selection,
li::-moz-selection {
  background: rgba(255, 107, 8, 0.8);
  color: white;
}

a::selection {
  background: rgb(22, 22, 22);
}
a::-moz-selection {
  background: rgb(22, 22, 22);
}


.offline-mode-banner{
  background: rgb(255, 107, 8);
  color: white;
  padding: 10px;
}